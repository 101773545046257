<template>
  <div>
    <a-button-group>
      <a-button icon="edit" size="small" @click="updateModalVisible = true">编辑</a-button>
      <a-popconfirm title="确定删除吗" @confirm="destroy">
        <a-button type="danger" icon="delete" size="small">删除</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定重置吗" @confirm="reset">
        <a-button type="primary" icon="redo" size="small">重置</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定解绑吗" @confirm="unbind">
        <a-button type="primary" icon="unlock" size="small">解绑</a-button>
      </a-popconfirm>
    </a-button-group>

    <update-form-modal v-model="updateModalVisible" :item="item" @update="update" @cancel="updateModalVisible = false" />
  </div>
</template>

<script>
import { crossingDestroy, crossingReset, crossingUnbind } from "@/apis/data";

export default {
  components: {
    UpdateFormModal: () => import("./UpdateFormModal"),
  },
  props: ["item"],
  data() {
    return {
      updateModalVisible: false,
    };
  },
  methods: {
    update(item) {
      this.$emit("update", item);
    },
    destroy() {
      crossingDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("destroy", this.item);
      });
    },
    reset() {
      crossingReset({ id: this.item.id }).then((data) => {
        this.$message.success("重置成功");
        this.$emit("update", data);
      });
    },
    unbind() {
      if (!this.item.con_number || this.item.con_number === "") {
        this.$message.warning("未绑定 CON, 无法解绑");
        return;
      }

      crossingUnbind({ crossing_name: this.item.name, con_number: this.item.con_number }).then((data) => {
        this.$message.success("解绑成功");
        this.$emit("update", data);
      });
    },
  },
};
</script>

<style scoped></style>
